<template>
    <component :is="activeDashboard"></component>
</template>
<script>
const CompanyDashboard = () => import("./company/Dashboard.vue");
const CandidateDashboard = () => import("./candidate/Dashboard.vue");
import {mapState} from 'vuex';

export default {

    components: {
        CompanyDashboard,
        CandidateDashboard
    },

    computed: {
        ...mapState(['company']),

        activeDashboard() {
            return this.company !== null ? CompanyDashboard : CandidateDashboard;
        }
    }

}
</script>
